import lazySizes from 'lazysizes'
import Headroom from 'headroom.js'
import SmoothScroll from 'smooth-scroll'
import moment from 'moment'
import DateRangePicker from 'daterangepicker'

require('./modernizrWebp.js')
require('./fresco')
require('jquery-match-height')
require('velocity-animate') // Javascipt engine for snappier animations

jQuery(function ($) {
  const bodyTag = $('body')
  const body = document.querySelector('body')

  //
  // Hamburger and Nav
  //

  const hamburgerTag = $('.c-hamburger')
  const navigationTag = $('.c-nav')

  hamburgerTag.on('click', function (e) {
    hamburgerTag.toggleClass('is-active')
    navigationTag.toggleClass('is-active')
    bodyTag.toggleClass('menu-open')
    preventScroll()
  })

  $(document).on('keydown', function (e) {
    if (e.keyCode === 27) { // ESC
      hamburgerTag.removeClass('is-active')
      navigationTag.removeClass('is-active')
      bodyTag.removeClass('menu-open')
      preventScroll()
    }
  })

  // Prevent scroll when menu is open
  function preventScroll () {
    if ($('body').hasClass('menu-open')) {
      $('html, body').css({
        overflow: 'hidden'
      })
    } else {
      $('html, body').css({
        overflow: 'auto'
      })
    }
  }

  //
  // Submenus in the main header
  //

  $('.c-menu__submenu-trigger').click(function () {
    var self = $(this).parent()
    // If visible, hide it, otherwise show the submenu
    // Essentially operates as a toggle
    var slideDir = self.next('.c-menu__submenu-container').is(':visible') ? 'slideUp' : 'slideDown'
    self.next('.c-menu__submenu-container').velocity(slideDir)
    // Hide other submenus that are currently when another dropdown is clicked
    $('.c-menu__link-container').not(self).next().velocity('slideUp')
    // Add active class to active submenus
    var activeClass = self.is(':visible') ? 'submenu-active' : ''
    self.toggleClass(activeClass)
    // Remove active class from other dropdowns when another dropdown is clicked
    $('.c-menu__link-container').not(self).removeClass('submenu-active')
  })

  /**
   * MatchHeight
   */
  $('.item').matchHeight()

  //
  // If anchor links are near the top of window (stuck), add class
  //
  const anchorTop = function () {
    var anchorDistance = $('.c-anchors').offset().top
    var $window = $(window)
    // maybe throttle scroll here
    $window.scroll(function () {
      if ($window.scrollTop() >= anchorDistance) {
        $('.c-anchors').addClass('js-anchor--top')
      } else {
        $('.c-anchors').removeClass('js-anchor--top')
      }
    })
  }

  if ($('.c-anchors').length > 0) {
    anchorTop()
  }

  //
  // Anchor mobile trigger
  //

  const anchorTrigger = $('#c-anchors__trigger')
  const anchorList = $('.c-anchors__links')

  anchorTrigger.click(function () {
    var slideDir = anchorList.is(':visible') ? 'slideUp' : 'slideDown'
    anchorList.velocity(slideDir)
    bodyTag.toggleClass('anchor-active')
  })

  /**
   * Lazy Sizes
   */
  lazySizes.init()

  //
  // filters for Events
  //
  const filterToggle = $('#c-filter__toggle')
  const filterTag = $('.c-events__filters')

  const filtersToggle = function () {
    filterTag.toggleClass('is-active')
    bodyTag.toggleClass('filters-open')
  }

  filterToggle.on('click', function (e) {
    filtersToggle()
  })

  //
  // FAQs toggle
  //

  $('.c-faq-item__q').click(function () {
    var self = $(this)
    var slideDir = self.next().is(':visible') ? 'slideUp' : 'slideDown'
    self.next().velocity(slideDir)
    self.parent().toggleClass('faq-open')
    $('.c-faq-item__q').not(self).next().velocity('slideUp')
    $('.c-faq-item__q').not(self).parent().removeClass('faq-open')
  })

  //
  // Accordion toggle - Used in donation levels
  //

  $('.c-accordion__title-container').click(function () {
    var self = $(this)
    var slideDir = self.next().is(':visible') ? 'slideUp' : 'slideDown'
    self.next().velocity(slideDir)
    self.parent().toggleClass('state-open')
    $('.c-accordion__title-container').not(self).next().velocity('slideUp')
    $('.c-accordion__title-container').not(self).parent().removeClass('state-open')
  })

  //
  // Add random placeholder image for events/mastheads etc.
  //

  // const imgAppend = function () {
  //   const lower = 0
  //   const upper = 4
  //   let baseUrl = window.location.origin // doesn't work locally
  //   const urlString = baseUrl + '/lensic/themes/childtheme/assets/media/placeholderimages/placeholder' + (Math.floor(Math.random() * (upper - lower)) + lower) + '.jpg'
  //   this.src = urlString
  //   console.log('img changed')
  // }
  // $('html').find('img.js-img-append').each(function () {
  //   imgAppend()
  // })

  $('html').find('.js-img-append').each(function () {
    const lower = 0
    const upper = 4
    let baseUrl = window.location.origin // doesn't work locally
    const urlString = baseUrl + '/themes/childtheme/assets/media/placeholderimages/placeholder' + (Math.floor(Math.random() * (upper - lower)) + lower) + '.jpg'
    this.src = urlString
  })

  //
  // Do search results exist? if so add class to search panel
  //

  if ($('#c-search__results').length) {
    $(bodyTag).addClass('state--has-search-results')
  }

  //
  // Smooth Scroll
  //

  const headerHeight = $('.c-header').height()

  const scroll = new SmoothScroll('a[data-scroll]', {
    offset: headerHeight + 50,
    emitEvents: true
  })
  const options = { speed: 1000, easing: 'easeOutCubic' }
  scroll.animateScroll(options)

  // Log scroll events
  var logScrollEvent = function (event) {
    // The event type
    console.log('type:', event.type)
  }

  // Listen for scroll events
  document.addEventListener('scrollStart', logScrollEvent, true)
  document.addEventListener('scrollStop', logScrollEvent, true)

  // document.addEventListener('scrollStart', disableHeadroom())
  // document.addEventListener('scrollStop', enableHeadroom())

  // const disableHeadroom = function () {
  //   let headroom = headroomHeader
  //   if (headroom) {
  //     headroom.scroller.removeEventListener('scroll', headroom.debouncer, false)
  //   }
  //   // headroom.scroller.removeEventListener('scroll', headroom.debouncer, false)
  //   console.log('test2')
  // }

  // //
  // // Re-enable headroom once smooth scroll has finished
  // //
  // var enableHeadroom = () => {
  //   let headroom = headroomHeader
  //   if (headroom) {
  //     headroom.scroller.addEventListener('scroll', headroom.debouncer, false)
  //   }
  // }

  /**
   * Headroom Header
   */
  const headroomHeader = new Headroom(body, {
    'offset': 205,
    'tolerance': 5
  })
  headroomHeader.init()

  //
  // Add inactive class to unhovered list items
  //

  $('.c-footer__nav a').hover(function () {
    $('.c-footer__nav a').not(this).toggleClass('not-active')
  })

  $('.c-footer__social a').hover(function () {
    $('.c-footer__social a').not(this).toggleClass('not-active')
  })

  $('.c-anchor__link').hover(function () {
    $('.c-anchor__link').not(this).toggleClass('not-active')
  })

  $('.c-header__link').hover(function () {
    $('.c-header__link').not(this).toggleClass('not-active')
  })

  $('.page-numbers').hover(function () {
    $('.page-numbers').not(this).toggleClass('not-active')
  })

  //
  // Inline Font Awesome additons
  //

  $('.c-cta--inline').append("<i class='far fa-caret-right'></i>")
  $('.c-col-content a[href$=".pdf"]').append('<i class="far fa-file-pdf"></i>')

  // Date picker
  const datePickerField = $('.c-date-select-range')

  if (datePickerField.length > 0) {
    const currentDateRangeValue = function () {
      var val = datePickerField.val()
      var dates = val.split('+')

      if (val && dates.length === 2) {
        return {
          start: moment(dates[0], 'DD-MM-YYYY'),
          end: moment(dates[1], 'DD-MM-YYYY')
        }
      }

      return {
        start: moment(),
        end: undefined
      }
    }

    const dateRangePicker = new DateRangePicker(datePickerField, {
      autoUpdateInput: false,
      locale: {
        format: 'DD MMM',
        cancelLabel: 'Clear'
      },
      linkedCalendars: true,
      alwaysShowCalendars: true,
      startDate: currentDateRangeValue().start,
      endDate: currentDateRangeValue().end,
      minDate: moment()
    })

    datePickerField.on('apply.daterangepicker', function () {
      $(this).val(dateRangePicker.startDate.format('DD-MM-YYYY') + '+' + dateRangePicker.endDate.format('DD-MM-YYYY'))
      $(this).parents('form').submit()
    })

    datePickerField.on('cancel.daterangepicker', function () {
      $(this).val('')
    })
  }

  // Submit Event Filters
  const selectFilters = $('select#genre, select#venue, select#producer, select#season')

  selectFilters.on('change', function () {
    $(this).parents('form').submit()
  })
})
